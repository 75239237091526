import http from '@/libs/http'
import axios from 'axios'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CARTA_AGOTADO_COMPRAS
const getPaginate = (payload) => {
  return http.get(`${baseUrl}/carta/getPaginate`, { params: { ...payload } }, {
    headers: {
      loading: true
    }
  })
}
const downloadPdf = (id) => {
  const url = `${baseUrl}/download/downloadCarta/${id}`
  const res = axios.getUri({ url })
  window.open(res, '_blank')
}
const deleteCarta = (id) => http.put(`${baseUrl}/carta/deleteCarta/${id}`, { headers: { loading: true } })
const getDetails = (id) => http.get(`${baseUrl}/carta/get-details/${id}`)
const getLaboratorios = (params) => http.get(`${baseUrl}/laboratorio/get-laboratorios`, {
  params: { ...params },
  headers: {
    loading: true
  }
})
const getMx = (params) => http.get(`${baseUrl}/medicamento/search-medicamento`, {
  params: { ...params },
  headers: {
    loading: true
  }
})
const getInfoCartas = (params) => http.get(`${baseUrl}/carta/get-info-carta-by-ids`, {
  params: { ...params },
  headers: {
    loading: true
  }
})
const createCarta = (payload) => http.post(`${baseUrl}/carta/create`, payload, {
  headers: {
    'Content-Type': 'multipart/form-data',
    loading: true
  }
})
const downloadReportCarta = (params) => {
  const url = `${baseUrl}/download/report-cartas`
  const res = http.getUri({ url, params }, {
    headers: {
      loading: true
    }
  })
  window.open(res, '_blank')
}

const downloadPlantillaMasivo = () => {
  const url = `${baseUrl}/download/plantilla-cargue-masivo`
  const res = axios.getUri({ url })
  window.open(res, '_blank')
}

const loadPlantilla = (payload) => http.post(`${baseUrl}/carta/load-medicamentos-masivo`, payload, {
  headers: {
    'Content-Type': 'multipart/form-data',
    loading: true
  }
})
export {
  getPaginate,
  downloadPdf,
  deleteCarta,
  getLaboratorios,
  getMx,
  getInfoCartas,
  createCarta,
  getDetails,
  downloadReportCarta,
  downloadPlantillaMasivo,
  loadPlantilla
}
