<template>
    <div class="flex justify-start bg-white p-2 header-title divheader">
        <div class="grid-cols-2  font-bold my-auto" >
            <h2 class="text-lg">
            <i class="pi pi-align-justify" style="font-size: 1rem"></i>
                Listado Cartas Agotado
            </h2>
        </div>
        <div>
            <Button v-if="$can('pharmasan.compras.carta-agotado.crear')" icon="pi pi-plus" label="Crear Registro" class="btn-create" @click="$router.push({name: 'pharmasan.compras.carta-agotado.crear-carta'})" />
        </div>
    </div>
        <div class="grid gap-4 gap-y-6 p-5 text-sm grid-cols-2 bg-white md:grid-cols-12">
            <div class="md:col-span-3" >
                <label>Fecha Inicial:</label>
                <InputText class="rounded w-full border" type="date" v-model="filters.fechaInicial"/>
            </div>
            <div class="md:col-span-3" >
                <label>Fecha Final:</label>
                <InputText class="rounded w-full border" type="date" v-model="filters.fechaFinal"/>
            </div>
            <!-- <div class="md:col-span-3" >
                <label>Fecha Carta:</label>
                <InputText class="rounded w-full border" type="date" v-model="filters.fecha_carta"/>
            </div> -->
            <div class="md:col-span-3" >
                <div class="md:col-span-3" >
                    <label>Laboratorio:</label>
                    <Dropdown v-model="filters.laboratorio"
                        :options="laboratoriosList"
                        optionLabel="laboratorio"
                        optionValue="laboratorio"
                        placeholder="Seleccione un laboratorio"
                        :filter="true" :showClear="true"
                        class="rounded w-full border"
                        :filterPlaceholder="'Ingrese 4 o mas caracteres para buscar'"
                        @filter="getLaboratoriosList"
                    />
                </div>
            </div>
            <div class="md:col-span-3" >
                <label>Medicamento:</label>
                <Dropdown v-model="filters.mx"
                    :options="arrayMx"
                    optionLabel="nombre_medicamento"
                    optionValue="ItemCode"
                    placeholder="Seleccione un medicamento"
                    :filter="true"
                    :filterPlaceholder="'Ingrese 4 o mas caracteres para buscar'"
                    class="rounded w-full border"
                    @filter="handleSearch"
                />
            </div>
        </div>
        <div class="grid gap-4 gap-y-6 p-4 text-sm grid-cols-2 bg-white md:grid-cols-6" style="display:flex;justify-content:flex-end">
            <Button label="Limpiar" icon="pi pi-times" class="p-button-outlined p-button-secondary"  @click="clearFilters"/>
            <Button label="Buscar" icon="pi pi-search" class="p-button-outlined" @click="getLoad"/>
            <Button label="Excel" icon="pi pi-file-excel" class="p-button-outlined p-button-secondary" @click="downloadReport"/>
        </div>
  </template>
  <script>
import { computed, ref, onMounted } from 'vue'
import storeCartaAgotado from '../../store/store.cartaAgotado'
import { getLaboratorios, getMx, downloadReportCarta } from '../../services/cartaAgotado.service'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
  export default {
    name: 'filtros',
    props: {
      title: {
        type: String,
        default: 'Carta Agotado'
      }
    },
    emits: ['closeExpandAll'],
    setup (props, { emit }) {
        const laboratoriosList = ref([])
        const arrayMx = ref([])
        const offset = ref(0)
        const page = ref(0)
        const limit = ref(10)
        const totalRecords = ref(0)
        const filters = computed(() => storeCartaAgotado.getters.getFilters)
        const getLoad = () => {
            const payload = []
            emit('closeExpandAll')
            storeCartaAgotado.commit('setResponse', payload)
            if (filters.value.fechaInicial !== '' && filters.value.fechaFinal !== '') {
                if (filters.value.fechaInicial > filters.value.fechaFinal) {
                    Swal.fire({
                        title: 'Advertencia',
                        text: 'La fecha final no puede ser menor a la fecha inicial.',
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ok'
                    })
                } else {
                    storeCartaAgotado.dispatch('getLoad')
                }
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'Por favor ingresar un rango de fechas de inicio y fin.',
                    showConfirmButton: true,
                    showCancelButton: true
                })
            }
        }
        const handleSearch = async ({ value }) => {
            if (value.length > 4) {
                const params = {
                    search: value.toUpperCase()
                }
                await getMx(params).then(({ data }) => {
                    arrayMx.value = data
                })
            }
        }
        const getLaboratoriosList = ({ value }) => {
            const params = {
                    search: value.toUpperCase()
            }
            if (value.length > 4) {
                getLaboratorios(params).then(({ data }) => {
                laboratoriosList.value = data
                })
            }
        }
        const clearFilters = () => {
            storeCartaAgotado.commit('reloadFilters')
            getLoad()
        }
        const downloadReport = () => {
          downloadReportCarta(filters.value)
        }
        onMounted(() => {
            const date = new Date()
            const now = new Date()
            const lastMonth = date.setMonth(date.getMonth() - 1)
            filters.value.fechaInicial = dayjs(lastMonth).format('YYYY-MM-DD')
            filters.value.fechaFinal = dayjs(now).format('YYYY-MM-DD')
            getLoad()
        })
      return {
        props,
        filters,
        getLoad,
        totalRecords,
        limit,
        offset,
        page,
        clearFilters,
        laboratoriosList,
        handleSearch,
        arrayMx,
        getLaboratoriosList,
        downloadReport
    }
    }
  }
  </script>
  <style scoped>
  .header-title {
    background-color: #1c3faa;
    justify-content: space-between;
    color: white;
    border-radius: 5px;
  }
.btn-create {
    color: #1C3FAA;
    background: #fff;
}
  </style>
