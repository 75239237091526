import { createStore } from 'vuex'
import { downloadPdf, getPaginate, deleteCarta, getDetails } from '../services/cartaAgotado.service'
import dayjs from 'dayjs'
const date = new Date()
const now = new Date()
const lastMonth = date.setMonth(date.getMonth() - 1)
const storeCartaAgotado = {
  state: {
    _filters: {
        fechaFinal: dayjs(now).format('YYYY-MM-DD'),
        fechaInicial: dayjs(lastMonth).format('YYYY-MM-DD'),
        // fecha_carta: null,
        laboratorio: null,
        mx: null,
        offset: 0,
        page: 1,
        limit: 10,
        cartas_ids: []
    },
    _cartasAgotado: [],
    _totalRecords: 0,
    _disabledRemove: false
  },
  getters: {
    getFilters: state => state._filters,
    getCartas: state => state._cartasAgotado,
    getTotalRecords: state => state._totalRecords,
    getDisableRemove: state => state._disabledRemove
  },
  mutations: {
    setFilters (state, payload) {
        state._filters = {
            fechaInicial: payload.fechaInicial,
            fechaFinal: payload.fechaFinal,
            // fecha_carta: payload.fecha_carta,
            laboratorio: payload.laboratorio,
            offset: payload.offset,
            page: payload.page,
            limit: payload.limit,
            cartas_ids: payload.cartas_ids
        }
    },
    reloadFilters (state) {
      state._filters = {
        fechaFinal: dayjs(now).format('YYYY-MM-DD'),
          fechaInicial: dayjs(lastMonth).format('YYYY-MM-DD'),
          // fecha_carta: null,
          laboratorio: null,
          mx: null,
          offset: 0,
          page: 1,
          limit: 10,
          cartas_ids: []
      }
    },
    setResponse (state, payload) {
        state._cartasAgotado = payload
    },
    setTotalRecords (state, payload) {
      state._totalRecords = payload
    },
    setDisableRemove (state, payload) {
      state._disabledRemove = payload
    },
    setCarta (state, payload) {
      state._cartasAgotado[payload.index].detalle = payload.detalle
    }
},
  actions: {
    downloadPdf (state, params) {
        return downloadPdf(params)
    },
    getPaginate (state, params) {
        return getPaginate(params)
    },
    deleteCarta (state, params) {
        return deleteCarta(params)
    },
    getDetails (state, params) {
      return getDetails(params)
    },
    getLoad ({ commit }) {
      return getPaginate(this.state._filters).then(({ data }) => {
        commit('setResponse', data.result)
        commit('setTotalRecords', data.count)
      })
    }
  }
}
const store = createStore(storeCartaAgotado)
export default {
  namespaced: true,
  ...store
}
